@tailwind base;
@tailwind utilities;
@tailwind components;

.body {
  font-family: "Roboto", sans-serif;
}

.h1 {
  font-family: "Arsenal", sans-serif;
  font-size: 3.75rem;
  font-weight: 400;
}

.h2 {
  font-family: "Arsenal", sans-serif;
  font-size: 2.25rem;
  font-weight: 400;
}

.h3 {
  font-family: "Arsenal", sans-serif;
  font-size: 1.6875rem;
  font-weight: 400;
}

.h4 {
  font-family: "Arsenal", sans-serif;
  font-size: 1.365rem;
  font-weight: 400;
}

.subhead1 {
  font-family: "Roboto", sans-serif;
  font-size: 1.375rem;
  font-weight: 400;
}

.subhead1_bold {
  font-family: "Roboto", sans-serif;
  font-size: 1.375rem;
  font-weight: 500;
}

.subhead2 {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0.03125rem;
}

.body_lg {
  font-family: "Roboto", sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.body {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.03125rem;
}

.body_sm {
  font-family: "Roboto", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.03125rem;
}

.body_xs {
  font-family: "Roboto", sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.03125rem;
}

.small_caps {
  font-family: "Roboto", sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.3125rem;
  letter-spacing: 0.03125rem;
  font-variant: all-small-caps;
}

input,
textarea {
  --tw-ring-shadow: 0 0 #000 !important;
}
